.login .container.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.login form {
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  margin-top: 15vh;
  display: flex;
  gap: 2rem;
}

.login label {
  font-weight: bold;
}

.login input[type="email"],
.login input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  color: var(--text-color);
  background-color: var(--light-grey-color);
  border-radius: 4px;
  transition: border-color 0.3s ease;
  box-sizing: border-box; 
}

body.dark-mode .login input {
  border-color: var(--grey-color-dark);
  background-color: var(--light-grey-color-dark);
  color: var(--text-color-dark);
}

.login input[type="email"]:focus,
.login input[type="password"]:focus {
  outline: none;
}

.login .error-message {
  color: var(--error-color);
}

.login button[type="submit"],
.login button[type="button"] {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure buttons take up full width including padding and border */
}

.login .success-message {
  color: var(--success-color);
}

.forgot-password-link {
  cursor: pointer;
}

.login label,
.register label {
  font-weight: normal;
  margin-bottom: 0.5rem;
  display: inline-block; 
}

@media (max-width: 768px) {
  .login .container {
    width: calc(100vw - 10vw); 
    margin-left: 5vw; 
  }
}
