/* Light Mode Styles */
.container.view-codes h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--text-color);
}

.container.view-codes {
  width: 90%;
  border-collapse: collapse;
}

.container.view-codes th,
.container.view-codes td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid var(--grey-color);
  color: var(--text-color);
}

.container.view-codes th {
  font-weight: bold;
  background-color: var(--light-grey-color);
}

.container.view-codes tbody tr:nth-child(even) {
  background-color: var(--light-grey-color);
}

.container.view-codes tbody tr:hover {
  background-color: var(--light-grey-color);
}

.primary-button {
  background-color: var(--primary-color);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

/* Dark Mode Styles */
body.dark-mode .container.view-codes h2 {
  color: var(--text-color-dark);
}

body.dark-mode .container.view-codes th,
body.dark-mode .container.view-codes td {
  border-bottom: 1px solid var(--grey-color-dark);
  color: var(--text-color-dark);
}

body.dark-mode .container.view-codes th {
  background-color: var(--light-grey-color-dark);
}

body.dark-mode .container.view-codes tbody tr:nth-child(even) {
  background-color: var(--light-black-dark);
}

body.dark-mode .container.view-codes tbody tr:hover {
  background-color: var(--light-black);
}

body.dark-mode .primary-button {
  background-color: var(--primary-color-dark);
  color: var(--button-text-color-dark);
}
