/* Styles for Nav component */

/* Light mode */
.top-nav {
  width: 100%;
  background-color: var(--background-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  padding: 10px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.top-nav .logo {
  padding: 1.5rem;
  font-size: 18px;
  margin-bottom: -0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: var(--primary-color);
}

.top-nav .logo img {
  width: 16rem;
}

.top-nav .search-bar {
  flex: 1;
  text-align: center;
}

.top-nav .search-bar input {
  width: 100%;
  max-width: 200px;
  padding: 8px;
  border: 1px solid var(--grey-color);
  border-radius: 5px;
  font-size: 14px;
  box-sizing: border-box;
}

.top-nav .icons {
  padding: 0 5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4vw;
}

.top-nav .icons .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--text-color);
  cursor: pointer;
  padding: 4% 6%;
  border-radius: 2px;
}

.top-nav .icons .icon:hover {
  background-color: var(--light-grey);
}

.top-nav .icons .icon-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
}

.top-nav .icons .icon.dropdown {
  position: relative;
}

.top-nav .dropdown-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--background-color);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.top-nav .dropdown-content p {
  margin: 0;
  padding: 10px;
  cursor: pointer;
}

.top-nav .dropdown-content p:hover {
  background-color: var(--light-grey-color);
}

.top-nav .login-button {
  /* background: none; */
  border: none;
  /* color: var(--text-color); */
  margin-left: 1rem;
  cursor: pointer;
  font-size: 16px;
  padding: 0.5rem 1rem;
}

.search-bar {
  position: relative;
  display: flex;
  align-items: center;
}

.search-bar input {
  width: 200px; 
  padding-right: 30px; 
}

.search-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #888; 
}

.search-icon:hover {
  color: var(--primary-color); 
}

.search-icon:active {
  filter: brightness(0.8);
}

/* Dark mode */
html.dark-mode .top-nav {
  background-color: var(--background-color-dark);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

html.dark-mode .top-nav .logo {
  color: var(--primary-color);
}

html.dark-mode .top-nav .icons .icon {
  color: var(--text-color);
}

html.dark-mode .top-nav .icons .icon:hover {
  background-color: var(--light-black-dark);
}

html.dark-mode .top-nav .dropdown-content {
  background-color: var(--background-color-dark);
}

html.dark-mode .top-nav .dropdown-content p:hover {
  background-color: var(--light-black);
}


@media (max-width: 767px) {
  .top-nav {
    display: none;
  }

  .mobile-nav-top img {
    margin-top: 0.75rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .top-nav {
    display: none;
  }

  .mobile-nav-top img {
    margin-top: 0.75rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1439px) {
}

@media (min-width: 1440px) {

}