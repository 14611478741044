/* Browse.css */
.container {
  position: relative;
}

.filter-sort {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-sort label {
  display: block;
  margin-bottom: 10px;
}

.filter-sort select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.report-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.back-button {
  position: absolute;
  top: 8%;
  left: 5%;
  background-color: transparent;
  color: var(--primary-color); 
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
}

.back-button:hover {
  text-decoration: underline;
}

.back-button span  {
  font-size: 1.5rem;
}

/* Dark mode styles */
.dark-mode .browse-page {
  color: var(--text-color-dark);
}

.dark-mode .filter-sort select {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}



/* Add more specific dark mode styles as needed */

/* Responsive adjustments */
@media (max-width: 768px) {
  .report-list {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
