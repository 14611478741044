.upload h2 {
  text-align: center;
  margin-bottom: 20px;
}

.upload form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 500px; /* Add a max-width to the form */
  margin: 0 auto; /* Center the form */
}

.upload label {
  font-size: 1rem;
  font-weight: bold;
  color: var(--text-color);
}

.upload input,
.upload textarea,
.upload button {
  width: 100%; /* Ensure all elements take full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.upload input[type="text"],
.upload input[type="date"],
.upload textarea,
.upload input[type="file"] {
  padding: 10px;
  border: 1px solid var(--grey-color);
  border-radius: 4px;
  font-size: 1rem;
  color: var(--text-color);
  transition: background-color 0.3s, border-color 0.3s;
}

.upload input[type="file"] {
  padding: 0;
}

.upload textarea {
  resize: vertical;
  min-height: 100px;
}

.upload img {
  display: block;
  margin: 10px 0;
  border: 1px solid var(--grey-color);
  border-radius: 4px;
  max-width: 100px;
  height: auto;
}

.upload .error {
  color: var(--error-color);
  font-size: 0.875rem;
  text-align: center;
}

.upload button {
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.upload button:hover {
  background-color: rgba(175, 42, 35, 0.8);
}

.upload button:disabled {
  background-color: var(--grey-color);
  cursor: not-allowed;
}
