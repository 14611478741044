.dashboard.container {
  height: calc(100vh - 5vh); 
  box-sizing: border-box;
  overflow: hidden; 
  padding-top: 4vh;
  margin-left: 18vw;
  margin-bottom: 0;
  background-color: #333333;
  padding-bottom: 2vh;
}

.dashboard iframe {
  width: 100%; 
  height: 100%; 
  transform: scale(1); 
}

.dashboard .tool-bar {
  display: flex;
  width: 100%;
  justify-content:flex-end;
  align-items: center;
}

/* Dashboard.css */
.dashboard  .save-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding-bottom: 2vh;
  display: flex;
  align-items: center;
  color: var(--grey-color);
}

.save-button.saved .star-icon,
.save-button.saved {
  color: var(--warning-color);
}

::-webkit-scrollbar {
  width: 0px;
}


@media (max-width: 991px) {
  .dashboard.container {
    width: 100vw; 
    margin-left: 0vw; 
    margin-top: 5vh; 
    box-sizing: border-box;
    overflow: hidden;
  }

  .dashboard iframe {
    width: 100%; 
    height: 100%; 
  }
}

