:root {
  /* Light Theme */
  --background-color: #FFFFFF;
  --text-color: #1C1C1C;
  --grey-color: #6F6F6F;
  --light-grey-color: #F5F5F5;
  --primary-color: #AF2A23;
  --border-color: #ddd;

  /* Dark Theme */
  --background-color-dark: #202020;
  --text-color-dark: #FFFFFF;
  --grey-color-dark: #6F6F6F;
  --light-grey-color-dark: #303030;
  --primary-color-dark: #AF2A23;
  --border-color-dark: #333;
}

/* Default Styles for Light Mode */
.invite .container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.invite h2 {
  text-align: center;
  margin-bottom: 20px;
}

.invite .accordion {
  margin-bottom: 20px;
}

.invite .accordion-btn {
  width: 100%;
  padding: 10px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5vw;
}

.invite .panel {
  padding: 0 18px;
  background-color: var(--background-color);
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border: 1px solid var(--border-color);
  border-top: none;
}

.invite .form {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.invite .form label {
  margin-top: 10px;
  margin-bottom: 5px;
}

.invite .form select,
.invite .form input[type='text'],
.invite .form input[type='number'] {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.invite>div>button {
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--background-color);
  transition: background-color 0.3s;
}

.invite button:hover {
  background-color: rgba(175, 42, 35, 0.8);
}

.invite .copy-all-btn {
  margin-top: 10px;
  background-color: var(--primary-color);
  color: var(--background-color);
}

.invite .copy-all-btn:hover {
  background-color: rgba(175, 42, 35, 0.8);
}

.invite .generated-codes {
  margin-top: 20px;
}

.invite .generated-codes ul {
  list-style-type: none;
  padding: 0;
}

.invite .generated-codes li {
  background-color: var(--light-grey-color);
  border: 1px solid var(--border-color);
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite .generated-codes .code {
  font-weight: bold;
}

.invite .generated-codes .info {
  margin-left: 10px;
  font-size: 12px;
  color: var(--text-color);
}

.invite .copy-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invite .copy-btn:hover {
  color: rgba(175, 42, 35, 0.8);
}

.invite input {
  width: inherit;
}

/* Dark Mode Styles */
body.dark-mode .invite .container {
  color: var(--text-color-dark);
}


body.dark-mode .invite .panel {
  background-color: var(--background-color-dark);
  border: 1px solid var(--border-color-dark);
}

body.dark-mode .invite .form select,
body.dark-mode .invite .form input[type='text'],
body.dark-mode .invite .form input[type='number'] {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
  border: 1px solid var(--border-color-dark);
}

body.dark-mode .invite button {
  background-color: var(--primary-color-dark);
}



body.dark-mode .invite .generated-codes li {
  background-color: var(--light-grey-color-dark);
  border: 1px solid var(--border-color-dark);
}

body.dark-mode .invite .generated-codes .info {
  color: var(--text-color-dark);
}


body.dark-mode .invite .copy-btn:hover {
  color: rgba(175, 42, 35, 0.8);
}
