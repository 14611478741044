/* Light Mode Styles */
.analytics h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--text-color);
}

.analytics table {
  width: 90%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: var(--background-color);
}

.analytics table th,
.analytics table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid var(--grey-color);
  color: var(--text-color);
}

.analytics table th {
  font-weight: bold;
  background-color: var(--light-grey-color);
}

.analytics table tbody tr:nth-child(even) {
  background-color: var(--light-grey-color);
}

.analytics table tbody tr:hover {
  background-color: var(--light-grey-color);
}

/* Dark Mode Styles */
body.dark-mode .analytics h2 {
  color: var(--text-color-dark);
}

body.dark-mode .analytics table {
  background-color: var(--background-color-dark);
}

body.dark-mode .analytics table th,
body.dark-mode .analytics table td {
  border-bottom: 1px solid var(--grey-color-dark);
  color: var(--text-color-dark);
}

body.dark-mode .analytics table th {
  background-color: var(--light-grey-color-dark);
}

body.dark-mode .analytics table tbody tr:nth-child(even) {
  background-color: var(--light-black-dark);
}

body.dark-mode .analytics table tbody tr:hover {
  background-color: var(--light-black);
}
