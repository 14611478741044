
:root {
  --primary-red: #AF2A23;
  --muted-red: #c3312c;
  --light-red: #fc544f;
  --dark-red: #73140F;
  --grey: #868686;
  --light-grey: #EBEBEB;
  --white: #ffffff;
  --black: #000000;
  --light-black: #2F2F2F;
  --dark-grey: #202020;
}
/* src/components/Chatbox/Chatbox.css */
.chatbox-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; 
}

.chatbox-icon {
  cursor: pointer;
}

.chatbox-icon img {
  width: 3rem;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  border-radius: 100%;
}

.chatbox-icon img:hover {
  filter: brightness(0.8);
}

.chatbox-expanded {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

.close-btn {
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  display: block;
}

.bot a {
  color: var(--primary-red);
  font-style: italic;
  text-decoration: underline;
}

.chatbot p {
  margin: 0;
}

.chatbot {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.chatbox {
  margin-right: 3rem;
  margin-bottom: 1.5%;
  width: 400px;
  height: 80vh;
  background-color: var(--white);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: -4px -6px 25px rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.chatbot .header {
  background-image: url('../../assets/billbot/Header\ \(3\).png');
  background-repeat: no-repeat;
  height: 200px;
  position: relative;
  padding: 8%;
}

.chatbot .header img {
  width: 60%;
}

.chatbot .header .toggle-lang {
  width: 80px;
  height: 30px;
  position:absolute;
  display: flex;
  justify-content: flex-end;
  gap: 0.4rem;
  right: 1rem;
  top: 2.75rem;
  color: var(--white);
  font-weight: 300;
  cursor: pointer;
  font-size: 18px;
}

.chatbot .header button {
  position: absolute;
  font-size: 1.5rem;
  font-weight: 300;
  bottom: 105%;
  right: 0;
  background-color: transparent;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  position: absolute;
}

.chatbot .header .toggle-lang span.selected {
  font-weight: 700;
}

.chatbot .fixed {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 0.5rem;
}

.fixed .timestamp {
  align-self: center; 
}

.fixed .toggle-tts {
  position: absolute;
  right: 9%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0.3rem;
}
 
.fixed .toggle-tts img {
  width: 18px;
  height: 22px;
}

.fixed .toggle-tts label.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 22px;
  border-radius: 100px;
  background: linear-gradient(180deg, rgba(208,208,208,1) 28%, rgba(235,235,235,1) 100%);  display: inline-block; 
  cursor: pointer;
  position: relative;
  z-index: 1000;
  transition: 0.3s;
}

.fixed .toggle-tts label.button::before {
  position: absolute;
  content: '';
  background-color: var(--white);
  width: 18px;
  height: 18px;
  border-radius: 100px;
  margin: 2px;
  box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}

.fixed .toggle-tts input[type="checkbox"]:checked + .button {
  background: linear-gradient(180deg, rgba(175,42,35,1) 28%, rgba(195,49,44,1) 100%);
}

.fixed .toggle-tts input[type="checkbox"]:checked + .button::before {
  transform: translateX(28px);
  box-shadow: -3px 0px 8px rgba(0, 0, 0, 0.3);
}

.fixed .toggle-tts input {
  display: none;
}

.chatbot .conversation {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: relative;
  padding: 0% 5%;
  padding-bottom: 25px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatbot .conversation::-webkit-scrollbar {
  width: 12px;
  background-color: transparent; 
}

.chatbot .conversation::-webkit-scrollbar-thumb {
  background-color: var(--light-grey); 
  border-radius: 10px;
}

.chatbot .conversation::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.chatbot .conversation:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  cursor: pointer; 
}

.chatbot .timestamp {
  color: var(--grey);
}

.chatbot .suggestion {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 5px;
}

.chatbot .suggestion>p {
  color: var(--grey);
}

.chatbot .suggestion>div {
  width: 450px;
  box-sizing: border-box;
  overflow-x: auto; 
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  position: relative;
  padding-bottom: 10px;
}

.chatbot .suggestion>div::-webkit-scrollbar {
  height: 10px; 
  background-color: transparent; 
}

.chatbot .suggestion>div::-webkit-scrollbar-thumb {
  background-color: var(--light-grey); 
  background-clip: content-box;
  border-radius: 5px;
}

.chatbot .suggestion>div::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.chatbot .suggestion>div:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  cursor: pointer; 
}

.chatbot .suggestion .tag {
  color: var(--black);
  padding: 7px 10px;
  border-radius: 25px;
  border: 2px solid var(--primary-red);
  text-align: center;
  text-wrap: nowrap;
  cursor: pointer;
}

.chatbot .suggestion .tag.inactive {
  cursor:  not-allowed;
}

.chatbot .suggestion .tag:hover {
  box-shadow: 0px 2px 8px rgba(175, 42, 35, 0.2); 
}

.chatbot .suggestion .tag.selected {
  border: 2px solid var(--primary-red);
  box-shadow: 0px 2px 8px rgba(175, 42, 35, 0.2); 
  filter: brightness(0.8);
}

.chatbot .user,
.chatbot .bot {
  padding: 15px 20px;
  border-radius: 20px;
}

.chatbot .bot::before {
  position: absolute;
  left: -50px;
  top: 0;
  content: "";
  background-image: url('../../assets/billbot/bot_logo.png');
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;
}

.chatbot .user {
  margin-right: 50px;
  color: var(--white);
  background-color: var(--muted-red);
  position: relative;
  border-top-right-radius: 0px;
  margin-left: auto; 
}

.chatbot .user::before {
  position: absolute;
  right: -50px;
  top: 0;
  content: "";
  background-image: url('../../assets/billbot/User\ Logo.png');
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;
}

.chatbot .bot {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 50px;
  color: var(--black);
  background-color: var(--light-grey);
  position: relative;
  border-top-left-radius: 0px;
  margin-right: auto; 
}

.chatbot .bot::before {
  position: absolute;
  right: -50px;
  top: 0;
  content: "";
  background-image: url('../../assets/billbot/bot_logo.png');
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  display: block;
}

.chatbot .bot .tts {
  width: 100px;
}

.chatbot .pdf {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.chatbot .pdf-btn {
  width:175px;
  display: flex;
  padding: 0px;
  justify-content: start;
  align-items: center;
  border: none;
  cursor: pointer;
  background: transparent;
  position: relative;
  transition: 1s ease-out;
}

.chatbot .hide {
  display: none;
}

.chatbot .pdf-btn.fade-in {
  animation: fade-in 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.chatbot .pdf-btn img {
  width: 16px;
  height: 16px;
  cursor: pointer;
  padding: 2%;
  opacity: 1;
}


.chatbot .pdf-btn p {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border: 1px var(--primary-red);
  color: var(--dark-grey);
  transition: 0.4s ease-in-out;
  font-size: 14px;
  border: 1px solid #c3312c;
  padding: 1.5% 5.5%;
  border-radius: 50px;
  padding-bottom: 2.5%;
  padding-right: 15px;
  padding-top: 4px;
}

.chatbot .pdf-btn p:hover {
  background-color: var(--primary-red);
  color: white;
}

.chatbot .pdf-btn p img:nth-child(1) {
  transition: 0.4s ease-in-out;
}
.chatbot .pdf-btn p:hover img:nth-child(1) {
  filter: invert(1);
}

.chatbot .pdf-btn p.hide {
  display: none;
}

.chatbot img.pdf-load {
  left: 15px;
  width: 75px;
  height: 75px;
  position: absolute;
  opacity: 1;
  filter: invert(0);
}

.chatbot .pdf-search>p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.chatbot .pdf-search .load {
  width: 75px;
  display: none;
}

.chatbot .bot a.pdf-link {
  color: var(--muted-red);
  font-style: italic;
}

.user-input {
  background-color: var(--white);
  box-shadow: 0px -4px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  gap: 15px;
}

.user-input>img {
  width: 28px;
  height: 28px;
  opacity: 0.6;
  cursor: pointer;
}

.user-input input {
  width: 100%;
}

.user-input button {
  cursor: pointer;
}

.user-input input,
.user-input button {
  border: none;
  background-color: transparent;
}

.user-input input:focus {
  outline: none;
}

.user-input .send-button img {
  width: 30px;
  height: 30px;
}

.user-input button:hover {
  filter: brightness(0.8);
}

.user-input button.inactive {
  cursor: not-allowed;
  filter: grayscale(100%)
}

/* DARK MODE */
.chatbox.dark {
  background-color: var(--light-black);
}

.chatbox.dark .bot {
  background-color: var(--dark-grey);
  color: var(--white);
}

.chatbox.dark .suggestion>div::-webkit-scrollbar-thumb {
  background-color: var(--grey);
}

.chatbox.dark .suggestion .tag {
  color: var(--white);
}

.chatbox.dark .conversation::-webkit-scrollbar-thumb{
  background-color: var(--grey);
}

.chatbox.dark .user-input {
  background-color: var(--dark-grey);
}

.chatbox.dark .user-input input {
  color: var(--white);
}

.chatbox.dark .user-input button:hover {
  filter: brightness(1.3);
}

.chatbox.dark .user-input>img {
  filter: invert(1);
}

.chatbox.dark .toggle-tts {
  filter: brightness(1);
}

.chatbot .bot a.pdf-link.dark-mode {
  color: var(--light-red)
}

.chatbox.dark .bot>button img:first-child{
  filter: invert(1);
}

.chatbox.dark .bot>button  p {
  color: var(--white);
}

/* RESPONSIVE */
@media screen and (max-width: 600px) {
  .chatbot {
    display: flex;
    justify-content: center;
    padding: 5%;
    
  }
  .chatbox-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; 
   
  }

  .chatbox {
    position: fixed;
    right: 20px;
    width: 350px;
    margin: 0;
    bottom: 5px;
  }

  .chatbot .suggestion>div {
    width: 300px;
  }
}

@media screen and (max-width: 450px) {
  .chatbot .header {
    background-size: contain;
    padding: 5%;
  }

  .fixed {
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-content: center;
    gap: 0.75rem;
  }

  .fixed .toggle-tts {
    position: relative;
    right: 0px;
  }
}

@media screen and (max-height: 649px) {
  .chatbot .header {
    height: fit-content;
    background-position: 100% 100%;
  }
}

/* ANIMATION */
/* ----------------------------------------------
 * Generated by Animista on 2024-4-23 14:26:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
            opacity: 0;
  }
  50% {
    opacity: 0.25;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
            opacity: 0.7;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
