/* General styles for light and dark modes */
body.light-mode {
  --feedback-border-color: rgba(175, 42, 35, 0.3); 
  --feedback-background-color: var(--background-color); 
  --feedback-highlight-color: var(--primary-color); 
  --feedback-hover-color: rgba(175, 42, 35, 0.3); 
  --feedback-input-color: var(--primary-color); 
  --feedback-scale-num-background: var(--light-grey-color);
  --feedback-scale-num-border: var(--primary-color);
  --feedback-scale-num-active-background: var(--primary-color); 
  --feedback-scale-num-active-color: var(--background-color); 
}

body.dark-mode {
  --feedback-border-color: var(--dark-red);
  --feedback-background-color: var(--background-color-dark); 
  --feedback-highlight-color: var(--primary-color); 
  --feedback-hover-color: var(--light-black); 
  --feedback-input-color: var(--light-grey-color); 
  --feedback-scale-num-background: var(--background-color-dark); 
  --feedback-scale-num-border: var(--light-grey-color); 
  --feedback-scale-num-active-background: var(--text-color-dark); 
  --feedback-scale-num-active-color: var(--background-color-dark);
}

.feedback-container {
  margin-top: 15vh;
  margin-left: 18vw;
  height: 60vh;
  border-top: 5px solid var(--feedback-border-color);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.feedback-container h2 {
  font-weight: normal;
}

.feedback-container::before {
  position: absolute;
  content: "";
  bottom: 100%;
  left: -5vw;
  width: calc(100vw - 15vw); 
  margin-left: 1vw; 
  z-index: 0;
  height: 5px;
  background-color: var(--feedback-highlight-color);
  z-index: 999;
  transition: all 0.5s ease-in-out;
}

.feedback-container.one::before {
  width: 33vw;
}

.feedback-container.two::before {
  width: 66vw;
}

.feedback-container.three::before {
  width: 100vw;
}

.question-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
}

.feedback-container button {
  padding: 1.25% 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--feedback-highlight-color);
  color: var(--background-color);
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.question {
  position: relative;
  font-size: 1.25rem;
  color: var(--feedback-input-color);
}

.number {
  position: absolute;
  top: 8%;
  right: 102%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--feedback-highlight-color);
  gap: 3px;
  font-size: 0.9rem;
}

.number img {
  width: 20px;
}

.answer {
  width: 100%;
  color: var(--feedback-input-color);
}

.scale {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.scale-num {
  background-color: var(--feedback-scale-num-background);
  border: 1px solid var(--feedback-scale-num-border);
  color: var(--feedback-scale-num-border);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.scale-num:hover {
  background-color: var(--feedback-hover-color);
}

.scale-num.active {
  background-color: var(--feedback-scale-num-active-background);
  color: var(--feedback-scale-num-active-color);
}

.question-container input {
  padding: 0.75% 0;
  font-size: 1.25rem;
  color: var(--feedback-input-color);
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--feedback-border-color);
  background: transparent;
}

.question-container input:focus {
  border-bottom: 2px solid var(--feedback-highlight-color);
  outline: none;
  opacity: 1;
}

.finished {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}

.finished button {
  color: white;
  border-radius: 7px;
}

body.dark-mode .feedback-container input[type="text"] {
  background-color: transparent;
}

@media (max-width: 992px) {
  .feedback-container {
    margin-top: 15vh;
    margin-left: 0;
    height: 60vh;
    border-top: 5px solid var(--feedback-border-color);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }
}