/* General styles for light and dark modes */
body.light-mode {
  --card-background-color: var(--background-color);
  --card-text-color: var(--text-color);
  --card-border-color: var(--grey-color);
}

body.dark-mode {
  --card-background-color: var(--background-color-dark);
  --card-text-color: var(--text-color-dark);
  --card-border-color: var(--light-black-dark);
}

.container h2 {
  text-align: center;
  margin: 3rem 0;
}

/* Settings Component Styles */
.settings {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.settings h2 {
  text-align: center;
  margin-bottom: 20px;
}

.settings-error {
  color: var(--error-color);
  margin-bottom: 20px;
}

body.dark-mode .settings-error {
  color: var(--error-text-color-dark);
}

.settings-form-group {
  margin-bottom: 20px;
  width: 100%;
}

.settings-name-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;
}

.settings-form-group-name input {
  flex: 1;
  width: 100%;
}

.settings-form-group label {
  display: block;
  margin-bottom: 5px;
}

.settings-form-group input {
  padding: 10px;
  border: 1px solid var(--card-border-color);
  border-radius: 4px;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
}

.settings-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.settings-form-group-pswd {
  margin-bottom: 15px;
}

.settings-form-group-pswd label {
  display: block;
  margin-bottom: 5px;
}

.settings-form-group-pswd input {
  width: calc(100% - 120px);
  padding: 10px;
  border: 1px solid var(--card-border-color);
  border-radius: 4px;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
}

.settings-save-row {
  display: flex;
  align-items: center;
  gap: 10px;
}
