/* Light Mode Styles */
.access-requests h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--text-color); /* Text color for headings */
}

.access-requests table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: var(--background-color); /* Table background color */
}

.access-requests th,
.access-requests td {
  border: 1px solid var(--grey-color); /* Border color for table cells */
  padding: 8px;
  text-align: left;
  color: var(--text-color); /* Text color for table cells */
}

.access-requests th {
  font-weight: bold;
  background-color: var(--light-grey-color); /* Background color for headers */
}

.access-requests tbody tr:nth-child(even) {
  background-color: var(--light-grey-color); /* Background color for even rows */
}

.access-requests tbody tr:hover {
  background-color: var(--light-grey-color); /* Background color on hover */
}

/* Dark Mode Styles */
body.dark-mode .access-requests h2 {
  color: var(--text-color-dark); /* Text color for headings in dark mode */
}

body.dark-mode .access-requests table {
  background-color: var(--background-color-dark); /* Table background color in dark mode */
}

body.dark-mode .access-requests th,
body.dark-mode .access-requests td {
  border: 1px solid var(--grey-color-dark); /* Border color for table cells in dark mode */
  color: var(--text-color-dark); /* Text color for table cells in dark mode */
}

body.dark-mode .access-requests th {
  background-color: var(--light-grey-color-dark); /* Background color for headers in dark mode */
}

body.dark-mode .access-requests tbody tr:nth-child(even) {
  background-color: var(--background-color-dark); /* Background color for even rows in dark mode */
}

body.dark-mode .access-requests tbody tr:hover {
  background-color: var(--light-grey-color-dark); /* Background color on hover in dark mode */
}

/* Additional Styles */
.access-requests {
  padding: 20px;
}

.access-requests button {
  padding: 8px 12px;
  margin-left: 10px;
  cursor: pointer;
  background-color: var(--grey-color);
  color: #fff;
  border: none;
  border-radius: 4px;
}



.access-requests .verified-button,
.access-requests .ignored-button {
  background-color: grey;
  cursor: default;
  opacity: 0.7;
}

.access-requests .ignore-button {
  background-color: var(--grey-color);
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.access-requests .ignore-button:hover {
  background-color: var(--grey-color);
}

.access-requests .ignored-button {
  background-color: #ccc;
  color: #666;
  border: none;
  padding: 8px 12px;
}
