/* Light Mode */
body.light-mode {
  --background-color: #ffffff; 
  --text-color: #333333; /* Light mode text color */
  --banner-box-background: #ffffff; /* Light mode banner box background color */
  --card-background-color: #ffffff; /* Light mode card background color */
}

/* Dark Mode */
body.dark-mode {
  --background-color: #1a1a1a; /* Dark mode background color */
  --text-color: #ffffff; 
  --banner-box-background: #444444;
  --card-background-color: #444444; 
}

.home .banner-box {
  width: 90%; 
  height: 30vh; 
  margin-top: 5vh;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-image: url('../../assets/dashboard/Home\ Page\ Hero\ Section.png');
  background-size: cover; 
  background-position: center; 
}

.home .banner-content {
  width: 50%;
  margin-left: 35%;
  padding: 5%;
  position: relative;
}

.home .banner-content div {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 2vh;
}

.home .banner-content h1 {
  padding: 0;
  margin: 0;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
  line-height: 2.75rem;
}

.home .banner-content {
  color: white;
}

.home .banner-content img{
  position: absolute;
  bottom: 0;
  right: 2vw;
  width: 8rem;
}

@media (max-width: 768px) {
  
  .home .banner-content h1 {
    font-size: 1.75rem;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
    line-height: 2.5rem;
  }

  .card-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: center; 
  }

  .home .banner-content {
    width: 60%;
    margin-left: 30%;
    padding: 5%;
    height: 65%;
  }

  .home .banner-box {
    height: 25vh;
  }
}


@media (max-height: 768px) {
  .home .banner-content {
    padding: 7vh;
  }
}
