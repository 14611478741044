.register .container {
  width: 100%;
  max-width: 400px;
  margin-bottom: 10vh;
  height: 100%;
  overflow-y: auto;
}

.register form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 5vh;
  height: 100%;
  width: 100%;
  max-width: 400px;
}

.register form div {
  margin-bottom: 15px;
}

.register form label {
  margin-bottom: 5px;
}

.register form input[type="text"],
.register form input[type="email"],
.register form input[type="password"],
.register form input[type="submit"],
.register form button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box; /* Ensure full width including padding and border */
}

.register form input {
  color: var(--text-color);
  background-color: var(--light-grey-color);
}
body.register.dark-mode .register form input[type="text"] {
  border-color: var(--grey-color-dark);
  background-color: var(--light-grey-color-dark);
  color: var(--text-color-dark);
}

.register form input[type="submit"],
.register form button {
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register form input[type="submit"]:hover,
.register form button:hover {
  background-color: darken(var(--brand-color), 10%);
}

.register .container p {
  text-align: center;
  margin-top: 20px;
}

.register .container button {
  background-color: var(--accent-color);
  color: #fff;
  border: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register .container button:hover {
  background-color: darken(var(--accent-color), 10%);
}

.register .container span {
  color: var(--error-color);
  margin-top: 5px;
}

.register .container a {
  color: var(--accent-color);
  text-decoration: none;
}

.register .container a:hover {
  text-decoration: underline;
}

/* Dark mode styles */
.register.dark-mode .container {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.register.dark-mode form input[type="submit"],
.register.dark-mode form button {
  background-color: var(--brand-color-dark);
}

.register.dark-mode form input[type="submit"]:hover,
.register.dark-mode form button:hover {
  background-color: darken(var(--brand-color-dark), 10%);
}

.register.dark-mode .container button {
  background-color: var(--accent-color);
}

.register.dark-mode .container button:hover {
  background-color: darken(var(--accent-color), 10%);
}

.register.dark-mode .container span {
  color: var(--error-color);
}

.register .form-bot {
  margin-top: 20px;
}
