body.light-mode {
  --card-background-color: var(--background-color);
  --card-text-color: var(--text-color);
  --card-border-color: var(--grey-color);
}

body.dark-mode {
  --card-background-color: var(--background-color-dark);
  --card-text-color: var(--text-color-dark);
  --card-border-color: var(--light-black-dark);
}

.dashboard-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  height: 350px;
}

body.dark-mode .dashboard-card {
  border: 1px solid var(--card-border-color);
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

.dashboard-card:hover {
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
}

body.dark-mode .dashboard-card:hover {
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.4);
}

.thumbnail {
  width: 100%;
  height: 55%;
  overflow: hidden;
  border-bottom: 1px solid var(--card-border-color);
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  transition: transform 0.2s ease-in-out;
}

.details {
  width: 100%;
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem;
  box-sizing: border-box;
}

.card-title {
  font-size: 1.15rem;
  margin: 0;
}

.publication-date {
  font-size: 0.875rem;
  margin: 5px 0;
  opacity: 0.6;
}

.short-description {
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 3px 0;
  opacity: 0.8;
}

.dashboard-card .star-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: var(--grey-color);
}

.star-icon.saved {
  color: var(--warning-color);
}

.dashboard-card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dashboard-card .report-type {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
  margin-bottom: 5px;
  padding: 0; 
}

body.dark-mode .dashboard-card .report-type {
  color: var(--light-grey-dark);
}

.tags {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 5px;
}

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  border-radius: 4px;
  font-size: 0.8rem;
  color: white;
}

.new-tag {
  background-color: var(--primary-color);
}

.popular-tag {
  background-color: var(--primary-color);
}
