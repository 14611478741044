/* Base Variables */
:root {
  /* Colors */
  --primary-color: #AF2A23;

  --background-color: #FFFFFF;
  --text-color: #1C1C1C;
  --grey-color: #6F6F6F;
  --light-grey-color: #F5F5F5;

  --accent-color: #c3312c;
  --success-color: #2F2F2F;
  --warning-color: #c49500;
  --error-color: #73140F;

  --info-color: #007BFF;
  --success-bg-color: #D4EDDA;
  --success-text-color: #155724;
  --warning-bg-color: #FFEEBA;
  --warning-text-color: #856404;
  --error-bg-color: #F8D7DA;
  --error-text-color: #721C24;

  --grey: #868686;
  --light-grey: #EBEBEB;
  --black: #000000;
  --light-black: #2F2F2F;

  --muted-red: #c3312c;
  --dark-red: #73140F;
}

/* Dark Theme */
:root {
  --primary-color: #AF2A23;

  --background-color-dark: #202020;
  --text-color-dark: #FFFFFF;
  --grey-color-dark: #6F6F6F;
  --light-grey-color-dark: #202020;

  --accent-color-dark: #c3312c;
  --success-color-dark: #2F2F2F;
  --warning-color-dark: #FFD700;
  --error-color-dark: #73140F;

  --info-color-dark: #007BFF;
  --success-bg-color-dark: #155724;
  --success-text-color-dark: #D4EDDA;
  --warning-bg-color-dark: #856404;
  --warning-text-color-dark: #FFEEBA;
  --error-bg-color-dark: #721C24;
  --error-text-color-dark: hsl(355, 100%, 63%);

  --grey-dark: #868686;
  --light-grey-dark: #EBEBEB;
  --black-dark: #000000;
  --light-black-dark: #2F2F2F;

  --muted-red-dark: #c3312c;
  --dark-red-dark: #73140F;
}

html {
  font-size: 14px;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Nunito', sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: scroll;
}

h1, h2, h3 {
  font-family: 'Roboto', sans-serif;
}

p, .description {
  font-family: 'Nunito', sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

input[type="text"],
input[type="date"],
input[type="email"],
input[type="password"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--grey-color);
  border-radius: 4px;
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--light-grey-color);
  transition: background-color 0.3s, border-color 0.3s;
}

input[type="text"]:focus,
input[type="date"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
  border: 2px solid #AF2A23;
  outline: none;
}

body.dark-mode input[type="text"],
body.dark-mode input[type="date"],
body.dark-mode input[type="email"],
body.dark-mode input[type="password"],
body.dark-mode textarea {
  border-color: var(--grey-color-dark);
  background-color: var(--light-grey-color-dark);
  color: var(--text-color-dark);
}

body.dark-mode input[type="text"]:focus,
body.dark-mode input[type="date"]:focus,
body.dark-mode input[type="email"]:focus,
body.dark-mode input[type="password"]:focus,
body.dark-mode textarea:focus {
  border-color: var(--primary-color);
}

/* Flex container for the entire app */
.app-container {
  display: flex;
  height: 100%;
}

/* Reusable */
.container {
  background-color: var(--background-color);
}

.container {
  width: calc(100vw - 15vw);
  margin-left: 17vw;
  margin-top: 5vh;
  padding-top: 2vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  color: var(--text-color);
  padding-bottom: 10vh;
  overflow: auto;
  -ms-overflow-style: 10px;  
  scrollbar-width: 10px;  
}

.element-class::-webkit-scrollbar {
  display: 10px;
}

.section {
  margin-top: 5vh;
  width: 90%;
  margin-bottom: 10vh;
}

.card-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center; 
  background-color: var(--background-color); 
}

/* Primary Button Styles */
.primary-button {
  background-color: var(--primary-color);
  color: var(--background-color);
  position: relative;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  outline: none; 
  transition: all 0.3s ease-in-out; 
  border-radius: 4px;
  cursor: pointer;
}

.primary-button:hover {
  background-color: rgba(175, 42, 35, 0.7); 
}

.primary-button:active {
  background-color: rgba(175, 42, 35, 0.5);
  transform: scale(0.98); 
}

body.dark-mode .primary-button {
  background-color: var(--primary-color);
  color: var(--text-color-dark);
}

body.dark-mode .primary-button:hover {
  background-color: rgba(175, 42, 35, 0.8); /* Slightly darker color on hover */
}

body.dark-mode .primary-button:active {
  background-color: rgba(175, 42, 35, 0.6); /* Even darker color on active */
  transform: scale(0.98); /* Slightly reduce the size on click */
}

/* Media Queries for Responsive Design */
@media (max-width: 991px) {
  .container {
    width: 100vw; 
    margin-left: 0vw; 
    margin-top: 10vh; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10vh;
    overflow-y: scroll;
  }

  html {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1200px) and (max-width: 1439px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

@media (min-height: 600px) and (max-height: 799px) {
  /* Styles for height 600px to 799px */
}

@media (min-height: 800px) and (max-height: 999px) {
  /* Styles for height 800px to 999px */
}

@media (min-height: 1000px) and (max-height: 1199px) {
  /* Styles for height 1000px to 1199px */
}

@media (min-height: 1200px) {
  /* Styles for height >= 1200px */
}