.mobile-nav {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: absolute;
  z-index: 999;
  display: none;
  height: 9vh;
  background-color: var(--background-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.mobile-nav-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.mobile-nav-top img {
  height: 40px;
}

.mobile-nav .icon {
  cursor: pointer;
  font-size: 1.5rem;
}

.mobile-nav-menu {
  border-top: 2px solid var(--light-grey);
  box-shadow: -2px 5px 5px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-color);
  padding: 1rem;
  position: absolute;
  top: 9vh;
  right: 0;
  width: 50%;
  height: 100vh;
}

.mobile-nav .icons {
  padding: 0 10vw;;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2vw;
  margin-bottom: 1rem;
}

.mobile-nav .icons .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--text-color);
  cursor: pointer;
  padding: 2%;
  border-radius: 2px;
}

.mobile-nav .icons .icon:hover {
  background-color: var(--light-grey);
}

html.dark-mode .mobile-nav .icons .icon {
  color: var(--text-color);
}

html.dark-mode .mobile-nav .icons .icon:hover {
  background-color: var(--light-black-dark);
}

.mobile-nav-section {
  padding: 0 10vw;;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.mobile-nav-section h2 {
  margin-bottom: 0.25rem;
  font-size: 1rem;
}

.mobile-nav-section ul {
  list-style: none;
  padding: 0;
}

.mobile-nav-section ul li {
  margin-bottom: 0.25rem;
}

.mobile-nav-bottom {
  padding: 0 10vw;;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.mobile-nav-bottom .primary-button {
  margin: 1.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 100%;
}

html.dark-mode .mobile-nav {
  background-color: var(--background-color-dark);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

html.dark-mode .mobile-nav .logo {
  color: var(--primary-color);
}

html.dark-mode .mobile-nav .icons .icon {
  color: var(--text-color);
}

html.dark-mode .mobile-nav .icons .icon:hover {
  background-color: var(--light-black-dark);
}

html.dark-mode .mobile-nav .dropdown-content {
  background-color: var(--background-color-dark);
}

html.dark-mode .mobile-nav .dropdown-content p:hover {
  background-color: var(--light-black);
}

.mobile-nav ul li a:hover {
  color: var(--brand-color);
  background-color: var(--light-grey);
}

.mobile-nav ul li a {
  display: block;
  text-decoration: none;
  color: var(--text-color);
  padding: 7px 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
}

.mobile-nav ul li a.active-link {
  color: var(--brand-color);
  font-weight: bold;
  background-color: var(--light-grey);
}

.mobile-nav button {
  width: 30%;
}

body.dark-mode .mobile-nav {
  background-color: var(--background-color-dark);
  border-right: 2px solid var(--light-black-dark);
}

body.dark-mode .mobile-nav h2,
body.dark-mode .mobile-nav ul li a {
  color: var(--text-color-dark);
}

body.dark-mode .mobile-nav ul li a:hover {
  color: var(--brand-color-dark);
  background-color: var(--light-black-dark);
}

body.dark-mode .mobile-nav ul li a.active-link {
  color: var(--brand-color-dark);
  font-weight: bold;
  background-color: var(--light-black-dark);
}

@media (max-width: 767px) {
  .mobile-nav {
    display: flex;
  }

  .mobile-nav-menu {
    width: 100%;
    overflow-x: hidden;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .mobile-nav {
    display: flex;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1439px) {
}

@media (min-width: 1440px) {

}