
.sidenav {
  width: 15vw;
  height: 100%;
  background-color: var(--background-color);
  padding:  2vw;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  top: 4.35vh;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  border-right: 2px solid var(--light-grey);
  z-index: 1000;
  margin-top: 3vh;
  padding-bottom: 0;
  margin-bottom: 0;
}

.sidenav-bottom {
  margin-bottom: 17vh;
}

.sidenav h2 {
  margin-bottom: 10px;
  color: var(--text-color);
}

.sidenav ul {
  list-style-type: none;
  padding: 0;
}

.sidenav ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sidenav ul li {
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: color 0.3s ease, background-color 0.3s ease;
  color: var(--text-color);
  width: 85%;
  padding-left: 10px;
}

.sidenav ul li img {
  width: 1rem;
  height: 1rem; 
  margin-right: 10px;
}

.sidenav ul li img {
  filter: invert(0);
}

body.dark-mode .sidenav ul li img {
  filter: invert(1);
}

.sidenav ul li:hover {
  color: var(--brand-color);
  background-color: var(--light-grey);
}

.sidenav-section {
  width: 100%;
}

.sidenav button {
  width: 100%;
  margin-top: 20px; /* Add some margin for better spacing */
}

body.dark-mode .sidenav {
  background-color: var(--background-color-dark);
  border-right: 2px solid var(--light-black-dark);
}

body.dark-mode .sidenav h2,
body.dark-mode .sidenav ul li  {
  color: var(--text-color-dark);
}

body.dark-mode .sidenav ul li:hover {
  color: var(--brand-color-dark);
  background-color: var(--light-black-dark);
}

/* body.dark-mode .sidenav .active-link {
  color: var(--brand-color-dark);
  font-weight: bold;
  background-color: var(--light-black-dark);
} */

/* Adjustments for different screen heights */
@media (max-height: 599px) {
  .sidenav {
    padding: 1vw;
    font-size: 0.8rem;
  }
  .sidenav h2 {
    font-size: 0.875rem;
  }
  .sidenav ul li  {
    padding: 7px 15px;
  
  }
}

@media (min-height: 600px) and (max-height: 799px) {
  .sidenav {
    padding: 1.5vw;
    font-size: 0.9rem;
  }
  .sidenav h2 {
    font-size: 1rem;
  }
  .sidenav ul li  {
    padding: 7px 18px;
  }
}

@media (min-height: 800px) and (max-height: 999px) {
  .sidenav {
    padding: 1.5vw;
    font-size: 1rem;
  }
  .sidenav h2 {
    font-size: 1rem;
  }
  .sidenav ul li  {
    padding: 10px 20px;
  }
}

@media (min-height: 1000px) and (max-height: 1199px) {
  .sidenav {
    padding: 2vw;
    font-size: 1rem;
  }
  .sidenav h2 {
    font-size: 1.25rem;
  }
  .sidenav  {
    padding: 10px 20px;
  }
}

@media (min-height: 1200px) {
  .sidenav {
    padding: 2vw;
    font-size: 1rem;
  }
  .sidenav h2 {
    font-size: 1.25rem;
  }
  .sidenav  {
    padding: 10px 20px;
  }
}


@media (max-width: 767px) {
  .sidenav {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidenav {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
}

@media (min-width: 1200px) and (max-width: 1439px) {
}

@media (min-width: 1440px) {

}