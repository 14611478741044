/* General styles for light and dark modes */
body.light-mode {
  --accordion-background: var(--background-color);
  --accordion-text-color: var(--text-color);
  --accordion-border-color: var(--grey-color);
  --accordion-active-background: var(--light-grey-color);
  --accordion-icon-color: var(--grey-color);
}

body.dark-mode {
  --accordion-background: var(--background-color-dark);
  --accordion-text-color: var(--text-color-dark);
  --accordion-border-color: var(--light-black-dark);
  --accordion-active-background: var(--light-black-dark);
  --accordion-icon-color: var(--light-grey-dark);
}

/* Accordion Component Styles */
.accordion {
  box-sizing: border-box; 
}

.accordion .wrapper {
  width: 600px;
}

.accordion-item {
  border: 1px solid var(--accordion-border-color);
  border-radius: 8px;
  margin-bottom: 1.25rem;
  background-color: var(--accordion-background);
  overflow: hidden;
  width: 100%; 
  box-sizing: border-box; 
}

.accordion-title {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--accordion-text-color);
  font-weight: bold;
  transition: background-color 0.3s;
}

.accordion-title:hover {
  background-color: var(--accordion-active-background);
}

.accordion-icon {
  color: var(--accordion-icon-color);
  transition: transform 0.3s;
}

.accordion-content {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  line-height: 1.75rem;
  padding: 15px;
  display: none;
  color: var(--accordion-text-color);
  box-sizing: border-box; 
}

.accordion-item.active .accordion-content {
  display: block;
}

.accordion-item.active .accordion-icon {
  transform: rotate(180deg);
}
